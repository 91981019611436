<script>

import ListTemplate from "@/components/pages/List.vue"

export default {
  components: {ListTemplate},
  data() {
    return {
      tableDataTo: {
        agent: 0,
        hit: 0,
        items: [],
        late: 0,
        miss: 0,
      },
      tableConfig: [
        {
          prop: 'index',
          label: '序号',
          type: 'index',
          width: '60rem',
        },
        {
          prop: 'punch_in_status',
          label: '打卡状态',
          render: (row) => {
            return `<span style="color: ${(row.punch_in_status == 1 || row.punch_in_status == 4) ? '' : 'red'}">${row.punch_in_status_preview}</span>`
          }
        },
        {prop: 'check_date_preview', label: '日期(星期)'},
        {prop: 'section', label: '课节'},
        {prop: 'punch_in_time_preview', label: '打卡时间'},
        {prop: 'be_late_time_preview', label: '迟到时间'},
        {prop: 'is_agent', label: '是否为代课'},
        {prop: 'school_name', label: '校区'},
        {
          label: '操作',
          handle: true,
          width: '100rem',
          render: () => ['详情']
        },
      ],
    }
  },
  methods: {
    tableHandle(row) {
      this.$router.push({path: './details', query: {id: row.id}})
    }
  },
  created() {
    this.$_axios2.get('api/attendance/teacher-attendance/last-week-attendance').then(res => {
      this.tableDataTo = res.data.data
    })
  }
}
</script>

<template>
  <div>
    <div>考勤记录</div>
    <div class="title">
      {{ `实际出勤课时数: ${tableDataTo.hit}         迟到次数: ${tableDataTo.late}         未打卡次数: ${tableDataTo.miss}         代课次数: ${tableDataTo.agent}` }}
      <list-template ref="table" :table-config="tableConfig" :table-data="tableDataTo.items" @onHandle="tableHandle"></list-template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  color: #1D2129;
  font-size: 14rem;
}
</style>
